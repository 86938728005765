import React from "react";

export const PrivacyPolicyEng = () => {
  return (
    <div>
      <p class="privacy-title">Privacy Policy</p>
      <p class="privacy-text">Last updated March 04, 2024</p>
      <p>
        <span class="privacy-text">
          This privacy notice for Tigris Studios FZCO (
        </span>
        <span
          class="privacy-text"
          style={{ fontWeight: 700 }}
        >{` ${"we"}`}</span>
        <span class="privacy-text">&#39;, &#39;</span>
        <span class="privacy-text" style={{ fontWeight: 700 }}>
          us
        </span>
        <span class="privacy-text">&#39;, or &#39;</span>
        <span
          class="privacy-text"
          style={{ fontWeight: 700 }}
        >{` ${"our"} `}</span>
        <span class="privacy-text">
          ) describes how and why we might collect, store, use, and/or share
          process your information when you use our services.
        </span>
        <span class="privacy-text"></span>
        <span class="privacy-text"></span>
        <span class="privacy-text">Services</span>
        <span class="privacy-text">&#39;), such as when you:</span>
      </p>
      <br />
      <ul style={{ marginLeft: 50 }} class="lst-kix_u20d011nnaqh-0">
        <li class="privacy-text li-bullet-0">
          Download and use our mobile application (Niya), or any other
          application of ours that links to this privacy notice
        </li>
      </ul>
      <ul style={{ marginLeft: 50 }} class="lst-kix_2iyqqd89gag-0">
        <li class="privacy-text li-bullet-0">
          Engage with us in other related ways, including any sales, marketing,
          or events
        </li>
      </ul>
      <p class="privacy-text">
        <span class="privacy-text" style={{ fontWeight: 700 }}>
          Questions or concerns?{" "}
        </span>
        <span class="privacy-text">
          Reading this privacy notice will help you understand your privacy
          rights and choices. If you do not agree with our policies and
          practices, please do not use our Services. If you still have any
          questions or concerns, please contact us at hello@tigrisstudios.com.
        </span>
      </p>
      <br />
      <p class="privacy-text">
        <span class="privacy-subtitle">SUMMARY OF KEY POINTS</span>
      </p>
      <br />
      <p class="privacy-text">
        <span style={{ fontWeight: "bold" }} class="privacy-text">
          What personal information do we process?
        </span>
        <span class="c8">
          &nbsp;When you visit, use, or navigate our Services, we may process
          personal information depending on how you interact with us and the
          Services, the choices you make, and the products and features you use.
        </span>
      </p>
      <p class="privacy-text">
        <span style={{ fontWeight: "bold" }}>
          Do we process any sensitive personal information?
        </span>
        <span class="privacy-text">
          &nbsp;We do not process sensitive personal information.
        </span>
      </p>
      <br />
      <p class="privacy-text">
        <span style={{ fontWeight: "bold" }}>
          Do we receive any information from third parties?
        </span>
        <span class="privacy-text">
          &nbsp;We do not receive any information from third parties.
        </span>
      </p>
      <br />
      <p class="privacy-text">
        <span style={{ fontWeight: "bold" }}>
          How do we process your information?
        </span>
        <span class="c8">
          &nbsp;We process your information to provide, improve, and administer
          our Services, communicate with you, for security and fraud prevention,
          and to comply with law. We may also process your information for other
          purposes with your consent. We process your information only when we
          have a valid legal reason to do so.
        </span>
      </p>
      <p class="privacy-text">
        <span style={{ fontWeight: "bold" }}>
          In what situations and with which parties do we share personal
          information?
        </span>
        <span class="c8">
          &nbsp;We may share information in specific situations and with
          specific third parties.
        </span>
      </p>
      <br />
      <p class="privacy-text">
        <span style={{ fontWeight: "bold" }} className="privacy-text">
          How do we keep your information safe?
        </span>
        <span class="privacy-text">
          &nbsp;We have organisational and technical processes and procedures in
          place to protect your personal information. However, no electronic
          transmission over the internet or information storage technology can
          be guaranteed to be 100% secure, so we cannot promise or guarantee
          that hackers, cybercriminals, or other unauthorised third parties will
          not be able to defeat our security and improperly collect, access,
          steal, or modify your information.
        </span>
      </p>
      <p class="privacy-text">
        <span class="privacy-text">What are your rights?</span>
        <span class="privacy-text">
          &nbsp;Depending on where you are located geographically, the
          applicable privacy law may mean you have certain rights regarding your
          personal information.
        </span>
      </p>
      <br />
      <p class="privacy-text">
        <span style={{ fontWeight: "bold" }} class="privacy-text">
          How do you exercise your rights?
        </span>
        <span class="privacy-text">
          &nbsp;The easiest way to exercise your rights is by contacting us. We
          will consider and act upon any request in accordance with applicable
          data protection laws.
        </span>
      </p>
      <br />
      <p class="privacy-subtitle">TABLE OF CONTENTS </p>

      <p class="privacy-text">
        <span class="privacy-subtitle">1. WHAT INFORMATION DO WE COLLECT?</span>
      </p>
      <br />
      <p class="privacy-text">
        <span>Personal information you disclose to us</span>
      </p>
      <br />
      <p class="privacy-text">
        <span class="c8 c11">In Short: </span>
        <span class="c5">
          We collect personal information that you provide to us.
        </span>
      </p>

      <p class="privacy-text">
        <span>
          We collect personal information that you voluntarily provide to us
          when you register on the Services, express an interest in obtaining
          information about us or our products and Services, when you
          participate in activities on the Services, or otherwise when you
          contact us.
        </span>
      </p>

      <p class="privacy-text">
        <span>Personal Information Provided by You.</span>
        <span>
          &nbsp;The personal information that we collect depends on the context
          of your interactions with us and the Services, the choices you make,
          and the products and features you use. The personal information we
          collect may include the following:
        </span>
      </p>
      <ul style={{ marginLeft: 50 }} class="lst-kix_f3ruqz22ap4z-0 start">
        <li class="privacy-text li-bullet-0">names</li>
      </ul>
      <ul style={{ marginLeft: 50 }} class="lst-kix_dpq978rgavpk-0 start">
        <li class="privacy-text li-bullet-0">
          <span>phone numbers</span>
        </li>
      </ul>
      <ul style={{ marginLeft: 50 }} class="lst-kix_4rb1jli96y11-0 start">
        <li class="privacy-text li-bullet-0">
          <span>email addresses</span>
        </li>
      </ul>
      <ul style={{ marginLeft: 50 }} class="lst-kix_xgpu10kwca4l-0 start">
        <li class="privacy-text li-bullet-0">
          <span>usernames</span>
        </li>
      </ul>
      <p class="privacy-text">
        <span class="privacy-text" style={{ fontWeight: 700 }}>
          Sensitive Information.
        </span>
        <span>&nbsp;We do not process sensitive information.</span>
      </p>

      <p class="privacy-text">
        <span style={{ fontWeight: 700 }} class="privacy-text">
          Social Media Login Data.
        </span>
        <span class="privacy-text">
          We may provide you with the option to register with us using your
          existing social media account details, like your Facebook, Twitter, or
          other social media account. If you choose to register in this way, we
          will collect the information described in the section called below.
        </span>
      </p>

      <p class="privacy-text">
        <span class="privacy-text">Application Data.</span>
        <span class="privacy-text">
          &nbsp;If you use our application(s), we also may collect the following
          information if you choose to provide us with access or permission:
        </span>
      </p>
      <ul
        style={{ marginLeft: 50 }}
        class="privacy-text-l lst-kix_n4btud2b18qp-0"
      >
        <li class="privacy-text li-bullet-0">
          <span class="privacy-text" style={{ fontWeight: 700 }}>
            Geolocation Information.
          </span>
          <span>
            &nbsp;We may request access or permission to track location-based
            information from your mobile device, either continuously or while
            you are using our mobile application(s), to provide certain
            location-based services. If you wish to change our access or
            permissions, you may do so in your device&#39;s settings.
          </span>
        </li>
      </ul>
      <ul
        style={{ marginLeft: 50 }}
        class="privacy-text lst-kix_bscoae9ujb1f-0"
      >
        <li class="privacy-text li-bullet-0 ">
          <span class="privacy-text">Push Notifications.</span>
          <span class="privacy-text">
            &nbsp;We may request to send you push notifications regarding your
            account or certain features of the application(s). If you wish to
            opt out from receiving these types of communications, you may turn
            them off in your device&#39;s settings.
          </span>
        </li>
      </ul>
      <p class="privacy-text">
        <span class="privacy-text">
          This information is primarily needed to maintain the security and
          operation of our application(s), for troubleshooting, and for our
          internal analytics and reporting purposes.
        </span>
      </p>
      <p class="privacy-text">
        <span class="privacy-text">
          All personal information that you provide to us must be true,
          complete, and accurate, and you must notify us of any changes to such
          personal information.
        </span>
      </p>
      <p class="privacy-text-subtitle">
        <span class="c23 ">Information automatically collected</span>
      </p>
      <br />

      <p class="privacy-text">
        <span style={{ fontWeight: 700 }} class="c8 c11">
          In Short:
        </span>
        <span class="c5">
          Some information &mdash; such as your Internet Protocol (IP) address
          and/or browser and device characteristics &mdash; is collected
          automatically when you visit our Services.
        </span>
      </p>

      <p class="privacy-text">
        <span>
          We automatically collect certain information when you visit, use, or
          navigate the Services. This information does not reveal your specific
          identity (like your name or contact information) but may include
          device and usage information, such as your IP address, browser and
          device characteristics, operating system, language preferences,
          referring URLs, device name, country, location, information about how
          and when you use our Services, and other technical information. This
          information is primarily needed to maintain the security and operation
          of our Services, and for our internal analytics and reporting
          purposes.
        </span>
      </p>
      <p class="privacy-text">
        <span>
          Like many businesses, we also collect information through cookies and
          similar technologies.
        </span>
      </p>

      <p class="privacy-text">
        <span>The information we collect includes:</span>
      </p>
      <ul
        style={{ marginLeft: 50 }}
        class="privacy-text lst-kix_ljrzq5k8m7se-0 start"
      >
        <li class="privacy-text li-bullet-0">
          <span style={{ fontWeight: 700 }} class="privacy-text">
            Log and Usage Data.
          </span>
          <span class="privacy-text">
            &nbsp;Log and usage data is service-related, diagnostic, usage, and
            performance information our servers automatically collect when you
            access or use our Services and which we record in log files.
            Depending on how you interact with us, this log data may include
            your IP address, device information, browser type, and settings and
            information about your activity in the Services
          </span>
          <span>&nbsp;</span>
          <span>
            (such as the date/time stamps associated with your usage, pages and
            files viewed, searches, and other actions you take such as which
            features you use), device event information (such as system
            activity, error reports (sometimes called &#39;crash dumps&#39;),
            and hardware settings).
          </span>
        </li>
      </ul>
      <ul
        style={{ marginLeft: 50 }}
        class="privacy-text lst-kix_p6z25n6t0cl3-0 start"
      >
        <li class="privacy-text li-bullet-0">
          <span class="privacy-text" style={{ fontWeight: 700 }}>
            Device Data.
          </span>
          <span>
            &nbsp;We collect device data such as information about your
            computer, phone, tablet, or other device you use to access the
            Services. Depending on the device used, this device data may include
            information such as your IP address (or proxy server), device and
            application identification numbers, location, browser type, hardware
            model, Internet service provider and/or mobile carrier, operating
            system, and system configuration information.
          </span>
        </li>
      </ul>
      <ul
        style={{ marginLeft: 50 }}
        class="privacy-text lst-kix_w4cdx36vgk05-0 start"
      >
        <li class="privacy-text li-bullet-0">
          <span class="privacy-text" style={{ fontWeight: 700 }}>
            Location Data.
          </span>
          <span>
            &nbsp;We collect location data such as information about your
            device&#39;s location, which can be either precise or imprecise. How
            much information we collect depends on the type and settings of the
            device you use to access the Services. For example, we may use GPS
            and other technologies to collect geolocation data that tells us
            your current location (based on your IP address). You can opt out of
            allowing us to collect this information either by refusing access to
            the information or by disabling your Location setting on your
            device. However, if you choose to opt out, you may not be able to
            use certain aspects of the Services.
          </span>
        </li>
      </ul>
      <br />
      <p class="privacy-subtitle">2. HOW DO WE PROCESS YOUR INFORMATION?</p>
      <br />
      <p class="privacy-text">
        <span style={{ fontWeight: 700 }} class="c8 c11 ">
          In Short:
        </span>
        <span class="c5">
          We process your information to provide, improve, and administer our
          Services, communicate with you, for security and fraud prevention, and
          to comply with law. We may also process your information for other
          purposes with your consent.
        </span>
      </p>

      <p class="privacy-text">
        <span class="c39 c40 ">
          We process your personal information for a variety of reasons,
          depending on how you interact with our Services, including:
        </span>
      </p>
      <ul
        style={{ marginLeft: 50 }}
        class="privacy-text lst-kix_3mc1cokc5puj-0 start"
      >
        <li class="privacy-text li-bullet-0">
          <span class="privacy-text" style={{ fontWeight: 700 }}>
            To facilitate account creation and authentication and otherwise
            manage user accounts.
          </span>
          <span>
            We may process your information so you can create and log in to your
            account, as well as keep your account in working order.
          </span>
        </li>
      </ul>
      <ul style={{ marginLeft: 50 }} class="lst-kix_bs2x1kyakqkz-0 start">
        <li class="privacy-text li-bullet-0">
          <span class="privacy-text" style={{ fontWeight: 700 }}>
            To request feedback.{" "}
          </span>
          <span>
            We may process your information when necessary to request feedback
            and to contact you about your use of our Services.
          </span>
        </li>
      </ul>
      <ul style={{ marginLeft: 50 }} class="lst-kix_xd3n5isvaad-0 start">
        <li class="privacy-text li-bullet-0">
          <span class="privacy-text" style={{ fontWeight: 700 }}>
            To save or protect an individual&#39;s vital interest.
          </span>
          <span>
            &nbsp;We may process your information when necessary to save or
            protect an individual&rsquo;s vital interest, such as to prevent
            harm.
          </span>
        </li>
      </ul>

      <p class="privacy-text">
        <span class="privacy-subtitle">
          3. WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR INFORMATION?
        </span>
      </p>

      <p class="privacy-text">
        <span style={{ fontWeight: 700 }} class="c8 c11 ">
          In Short:
        </span>
        <span class="c5">
          We only process your personal information when we believe it is
          necessary and we have a valid legal reason (i.e. legal basis) to do so
          under applicable law, like with your consent, to comply with laws, to
          provide you with services to enter into or fulfil our contractual
          obligations, to protect your rights, or to fulfil our legitimate
          business interests.
        </span>
      </p>

      <p class="privacy-text">
        <span style={{ fontWeight: 700 }} class="c8 c11">
          If you are located in the EU or UK, this section applies to you.
        </span>
      </p>

      <p class="privacy-text">
        <span>
          The General Data Protection Regulation (GDPR) and UK GDPR require us
          to explain the valid legal bases we rely on in order to process your
          personal information. As such, we may rely on the following legal
          bases to process your personal information:
        </span>
      </p>
      <ul style={{ marginLeft: 50 }} class="lst-kix_ajd8tabf26nx-0 start">
        <li class="privacy-text li-bullet-0">
          <span class="privacy-text" style={{ fontWeight: 700 }}>
            Consent.{" "}
          </span>
          <span>
            We may process your information if you have given us permission
            (i.e. consent) to use your personal information for a specific
            purpose. You can withdraw your consent at any time.
          </span>
        </li>
      </ul>
      <ul style={{ marginLeft: 50 }} class=" lst-kix_cbkvo5hlgslz-0 start">
        <li class="privacy-text li-bullet-0">
          <span class="privacy-text" style={{ fontWeight: 700 }}>
            Legitimate Interests.
          </span>
          <span>
            &nbsp;We may process your information when we believe it is
            reasonably necessary to achieve our legitimate business interests
            and those interests do not outweigh your interests and fundamental
            rights and freedoms. For example, we may process your personal
            information for some of the purposes described in order to:
          </span>
        </li>
      </ul>
      <ul style={{ marginLeft: 50 }} class="lst-kix_cbkvo5hlgslz-0 start">
        <li class="privacy-text li-bullet-0">
          <span>
            Understand how our users use our products and services so we can
            improve user experience
          </span>
        </li>
      </ul>
      <ul style={{ marginLeft: 50 }} class="lst-kix_2p7l9rrxj63l-0 start">
        <li class="privacy-text li-bullet-0">
          <span class="privacy-text" style={{ fontWeight: 700 }}>
            Legal Obligations.
          </span>
          <span>
            &nbsp;We may process your information where we believe it is
            necessary for compliance with our legal obligations, such as to
            cooperate with a law enforcement body or regulatory agency, exercise
            or defend our legal rights, or disclose your information as evidence
            in litigation in which we are involved.
          </span>
        </li>
      </ul>
      <ul style={{ marginLeft: 50 }} class="lst-kix_54cqgamj4agb-0 start">
        <li class="privacy-text li-bullet-0">
          <span class="privacy-text" style={{ fontWeight: 700 }}>
            Vital Interests.
          </span>
          <span>
            &nbsp;We may process your information where we believe it is
            necessary to protect your vital interests or the vital interests of
            a third party, such as situations involving potential threats to the
            safety of any person.
          </span>
        </li>
      </ul>

      <p class="privacy-text">
        <span style={{ fontWeight: 700 }} class="c8 c11">
          If you are located in Canada, this section applies to you.
        </span>
      </p>

      <p class="privacy-text">
        <span>
          We may process your information if you have given us specific
          permission (i.e. express consent) to use your personal information for
          a specific purpose, or in situations where your permission can be
          inferred (i.e. implied consent).
        </span>
      </p>
      <p class="privacy-text">
        <span>
          In some exceptional cases, we may be legally permitted under
          applicable law to process your information without your consent,
          including, for example:
        </span>
      </p>
      <ul style={{ marginLeft: 50 }} class="lst-kix_5ubxyw78ebrs-0 start">
        <li class="privacy-text li-bullet-0">
          <span>
            If collection is clearly in the interests of an individual and
            consent cannot be obtained in a timely way
          </span>
        </li>
      </ul>
      <ul style={{ marginLeft: 50 }} class="lst-kix_si5cxzrjvqmz-0 start">
        <li class="privacy-text li-bullet-0">
          <span>For investigations and fraud detection and prevention</span>
        </li>
      </ul>
      <ul style={{ marginLeft: 50 }} class="lst-kix_h8meeb1jvms5-0 start">
        <li class="privacy-text li-bullet-0">
          <span>
            For business transactions provided certain conditions are met
          </span>
        </li>
      </ul>
      <ul style={{ marginLeft: 50 }} class="lst-kix_30ahrqz2332p-0 start">
        <li class="privacy-text li-bullet-0">
          <span>
            If it is contained in a witness statement and the collection is
            necessary to assess, process, or settle an insurance claim
          </span>
        </li>
      </ul>
      <ul style={{ marginLeft: 50 }} class="lst-kix_thd82oiwms5h-0 start">
        <li class="privacy-text li-bullet-0">
          <span>
            For identifying injured, ill, or deceased persons and communicating
            with next of kin
          </span>
        </li>
      </ul>
      <ul style={{ marginLeft: 50 }} class="lst-kix_e9zhnyhph0z8-0 start">
        <li class="privacy-text li-bullet-0">
          <span>
            If we have reasonable grounds to believe an individual has been, is,
            or may be victim of financial abuse
          </span>
        </li>
      </ul>
      <ul style={{ marginLeft: 50 }} class="lst-kix_vsqi94jvjiy8-0 start">
        <li class="privacy-text li-bullet-0">
          <span>
            If it is reasonable to expect collection and use with consent would
            compromise the availability or the accuracy of the information and
            the collection is reasonable for purposes related to investigating a
            breach of an agreement or a contravention of the laws of Canada or a
            province
          </span>
        </li>
      </ul>
      <ul style={{ marginLeft: 50 }} class="lst-kix_s0umrbd5kxbx-0 start">
        <li class="privacy-text li-bullet-0">
          <span>
            If disclosure is required to comply with a subpoena, warrant, court
            order, or rules of the court relating to the production of records
          </span>
        </li>
      </ul>
      <ul style={{ marginLeft: 50 }} class="lst-kix_d48758em8xx-0 start">
        <li class="privacy-text li-bullet-0">
          <span>
            If it was produced by an individual in the course of their
            employment, business, or profession and the collection is consistent
            with the purposes for which the information was produced
          </span>
        </li>
      </ul>
      <ul style={{ marginLeft: 50 }} class="lst-kix_79dsm6m8mh3i-0 start">
        <li class="privacy-text li-bullet-0">
          <span>
            If the collection is solely for journalistic, artistic, or literary
            purposes
          </span>
        </li>
      </ul>
      <ul style={{ marginLeft: 50 }} class="lst-kix_kh4r9vs3gz1b-0 start">
        <li class="privacy-text li-bullet-0">
          <span>
            If the information is publicly available and is specified by the
            regulations
          </span>
        </li>
      </ul>

      <p class="privacy-text">
        <span class="privacy-subtitle">
          4. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?
        </span>
      </p>

      <p class="privacy-text">
        <span style={{ fontWeight: 700 }} class="c8 c11 ">
          In Short:
        </span>
        <span class="c5">
          &nbsp;We may share information in specific situations described in
          this section and/or with the following third parties.
        </span>
      </p>

      <p class="privacy-text">
        <span>
          We may need to share your personal information in the following
          situations:
        </span>
      </p>
      <ul style={{ marginLeft: 50 }} class="lst-kix_136nuye0n766-0 start">
        <li class="privacy-text li-bullet-0">
          <span class="privacy-text" style={{ fontWeight: 700 }}>
            Business Transfers.
          </span>
          <span>
            &nbsp;We may share or transfer your information in connection with,
            or during negotiations of, any merger, sale of company assets,
            financing, or acquisition of all or a portion of our business to
            another company.
          </span>
        </li>
      </ul>
      <ul style={{ marginLeft: 50 }} class="lst-kix_khok4ll8cqmh-0 start">
        <li class="privacy-text li-bullet-0">
          <span class="privacy-text" style={{ fontWeight: 700 }}>
            When we use Google Maps Platform APIs.
          </span>
          <span>
            &nbsp;We may share your information with certain Google Maps
            Platform APIs (e.g. Google Maps API, Places API). We use certain
            Google Maps Platform APIs to retrieve certain information when you
            make location-specific requests. This includes: User Location; and
            other similar information. A full list of what we use information
            for can be found in this section and in the previous section titled
            We obtain and store on your device (&#39;cache&#39;) your location.
            You may revoke your consent anytime by contacting us at the contact
            details provided at the end of this document. The Google Maps
            Platform APIs that we use store and access cookies and other
            information on your devices. If you are a user currently in the
            European Economic Area (EU countries, Iceland, Liechtenstein, and
            Norway) or the United Kingdom, please take a look at our Cookie
            Notice.
          </span>
        </li>
      </ul>

      <p class="privacy-text">
        <span class="privacy-subtitle">
          5. WHAT IS OUR STANCE ON THIRD-PARTY WEBSITES?
        </span>
      </p>

      <p class="privacy-text">
        <span style={{ fontWeight: 700 }} class="c8 c11 ">
          In Short:
        </span>
        <span class="c5">
          &nbsp;We are not responsible for the safety of any information that
          you share with third parties that we may link to or who advertise on
          our Services, but are not affiliated with, our Services.
        </span>
      </p>

      <p class="privacy-text">
        <span>
          The Services may link to third-party websites, online services, or
          mobile applications and/or contain advertisements from third parties
          that are not affiliated with us and which may link to other websites,
          services, or applications. Accordingly, we do not make any guarantee
          regarding any such third parties, and we will not be liable for any
          loss or damage caused by the use of such third-party websites,
          services, or applications. The inclusion of a link towards a
          third-party website, service, or application does not imply an
          endorsement by us. We cannot guarantee the safety and privacy of data
          you provide to any third parties. Any data collected by third parties
          is not covered by this privacy notice. We are not responsible for the
          content or privacy and security practices and policies of any third
          parties, including other websites, services, or applications that may
          be linked to or from the Services. You should review the policies of
          such third parties and contact them directly to respond to your
          questions.
        </span>
      </p>

      <p class="privacy-text">
        <span class="privacy-subtitle">
          6. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?
        </span>
      </p>

      <p class="privacy-text">
        <span style={{ fontWeight: 700 }} class="c8 c11 ">
          In Short:
        </span>
        <span class="c5">
          &nbsp;We may use cookies and other tracking technologies to collect
          and store your information.
        </span>
      </p>

      <p class="privacy-text">
        <span>
          We may use cookies and similar tracking technologies (like web beacons
          and pixels) to access or store information. Specific information about
          how we use such technologies and how you can refuse certain cookies is
          set out in our Cookie Notice.
        </span>
      </p>

      <p class="privacy-text">
        <span class="privacy-subtitle">
          7. HOW DO WE HANDLE YOUR SOCIAL LOGINS?
        </span>
      </p>

      <p class="privacy-text">
        <span style={{ fontWeight: 700 }} class="c8 c11 ">
          In Short:
        </span>
        <span class="c5">
          If you choose to register or log in to our Services using a social
          media account, we may have access to certain information about you.
        </span>
      </p>

      <p class="privacy-text">
        <span>
          Our Services offer you the ability to register and log in using your
          third-party social media account details (like your Facebook or
          Twitter logins). Where you choose to do this, we will receive certain
          profile information about you from your social media provider. The
          profile information we receive may vary depending on the social media
          provider concerned, but will often include your name, email address,
          friends list, and profile picture, as well as other information you
          choose to make public on such a social media platform.
        </span>
      </p>

      <p class="privacy-text">
        <span>
          We will use the information we receive only for the purposes that are
          described in this privacy notice or that are otherwise made clear to
          you on the relevant Services. Please note that we do not control, and
          are not responsible for, other uses of your personal information by
          your third-party social media provider. We recommend that you review
          their privacy notice to understand how they collect, use, and share
          your personal information, and how you can set your privacy
          preferences on their sites and apps.
        </span>
      </p>

      <p class="privacy-text">
        <span class="privacy-subtitle">
          8. HOW LONG DO WE KEEP YOUR INFORMATION?
        </span>
      </p>

      <p class="privacy-text">
        <span style={{ fontWeight: 700 }} class="c8 c11 ">
          In Short:
        </span>
        <span class="c5">
          We keep your information for as long as necessary to fulfil the
          purposes outlined in this privacy notice unless otherwise required by
          law.
        </span>
      </p>

      <p class="privacy-text">
        <span>
          We will only keep your personal information for as long as it is
          necessary for the purposes set out in this privacy notice, unless a
          longer retention period is required or permitted by law (such as tax,
          accounting, or other legal requirements). No purpose in this notice
          will require us keeping your personal information for longer than the
          period of time in which users have an account with us.
        </span>
      </p>

      <p class="privacy-text">
        <span>
          When we have no ongoing legitimate business need to process your
          personal information, we will either delete or anonymise such
          information, or, if this is not possible (for example, because your
          personal information has been stored in backup archives), then we will
          securely store your personal information and isolate it from any
          further processing until deletion is possible.
        </span>
      </p>

      <p class="privacy-text">
        <span class="privacy-subtitle">
          9. HOW DO WE KEEP YOUR INFORMATION SAFE?
        </span>
      </p>

      <p class="privacy-text">
        <span style={{ fontWeight: 700 }} class="c8 c11 ">
          In Short:
        </span>
        <span class="c5">
          We aim to protect your personal information through a system of
          organisational and technical security measures.
        </span>
      </p>

      <p class="privacy-text">
        <span>
          We have implemented appropriate and reasonable technical and
          organisational security measures designed to protect the security of
          any personal information we process. However, despite our safeguards
          and efforts to secure your information, no electronic transmission
          over the Internet or information storage technology can be guaranteed
          to be 100% secure, so we cannot promise or guarantee that hackers,
          cybercriminals, or other unauthorised third parties will not be able
          to defeat our security and improperly collect, access, steal, or
          modify your information. Although we will do our best to protect your
          personal information, transmission of personal information to and from
          our Services is at your own risk. You should only access the Services
          within a secure environment.
        </span>
      </p>

      <p class="privacy-text">
        <span class="privacy-subtitle">10. WHAT ARE YOUR PRIVACY RIGHTS?</span>
      </p>

      <p class="privacy-text">
        <span style={{ fontWeight: 700 }} class="c8 c11 ">
          In Short:
        </span>
        <span class="c5">
          &nbsp;In some regions, such as the European Economic Area (EEA),
          United Kingdom (UK), Switzerland, and Canada, you have rights that
          allow you greater access to and control over your personal
          information. You may review, change, or terminate your account at any
          time.
        </span>
      </p>

      <p class="privacy-text">
        <span>
          In some regions (like the EEA, UK, Switzerland, and Canada), you have
          certain rights under applicable data protection laws. These may
          include the right (i) to request access and obtain a copy of your
          personal information, (ii) to request rectification or erasure; (iii)
          to restrict the processing of your personal information; (iv) if
          applicable, to data portability; and (v) not to be subject to
          automated decision-making. In certain circumstances, you may also have
          the right to object to the processing of your personal information.
          You can make such a request by contacting us by using the contact
          details provided in the section below.
        </span>
      </p>

      <p class="privacy-text">
        <span>
          We will consider and act upon any request in accordance with
          applicable data protection laws.
        </span>
      </p>
      <p class="privacy-text">
        <span class="c8">
          If you are located in the EEA or UK and you believe we are unlawfully
          processing your personal information, you also have the right to
          complain to your
        </span>
        <span class="c28">
          <a
            class="c15"
            href="https://www.google.com/url?q=https://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm&amp;sa=D&amp;source=editors&amp;ust=1710525136582807&amp;usg=AOvVaw3x_k-gX9sTFDbBw6BEB7a1"
          >
            Member State data protection authority
          </a>
        </span>
        <span class="c8">&nbsp;or </span>
        <span class="c24">
          <a
            class="c15"
            href="https://www.google.com/url?q=https://ico.org.uk/make-a-complaint/data-protection-complaints/data-protection-complaints/&amp;sa=D&amp;source=editors&amp;ust=1710525136583472&amp;usg=AOvVaw3N8n5CgmxE2hr0CeLpjTXk"
          >
            UK data protection authority
          </a>
        </span>
        <span>.</span>
      </p>

      <p class="privacy-text">
        <span class="c8">
          If you are located in Switzerland, you may contact the
        </span>
        <span class="c24">
          <a
            class="c15"
            href="https://www.google.com/url?q=https://www.edoeb.admin.ch/edoeb/en/home.html&amp;sa=D&amp;source=editors&amp;ust=1710525136583985&amp;usg=AOvVaw3DiztBW9GZPP5Fk_WIqGXY"
          >
            Federal Data Protection and Information Commissioner
          </a>
        </span>
        <span>.</span>
      </p>

      <p class="privacy-text">
        <span style={{ fontWeight: 700 }} class="c8">
          Withdrawing your consent:
        </span>
        <span>
          &nbsp;If we are relying on your consent to process your personal
          information, which may be express and/or implied consent depending on
          the applicable law, you have the right to withdraw your consent at any
          time. You can withdraw your consent at any time by contacting us by
          using the contact details provided in the section below.
        </span>
      </p>

      <p class="privacy-text">
        <span>
          However, please note that this will not affect the lawfulness of the
          processing before its withdrawal nor, when applicable law allows, will
          it affect the processing of your personal information conducted in
          reliance on lawful processing grounds other than consent.
        </span>
      </p>

      <p class="privacy-text">
        <span class="c23 ">Account Information</span>
      </p>

      <p class="privacy-text">
        <span>
          If you would at any time like to review or change the information in
          your account or terminate your account, you can:
        </span>
      </p>
      <ul style={{ marginLeft: 50 }} class="lst-kix_ectsvttyb6hb-0 start">
        <li class="privacy-text li-bullet-0">
          <span>
            Log in to your account settings and update your user account.
          </span>
        </li>
      </ul>
      <p class="privacy-text">
        <span>
          Upon your request to terminate your account, we will deactivate or
          delete your account and information from our active databases.
          However, we may retain some information in our files to prevent fraud,
          troubleshoot problems, assist with any investigations, enforce our
          legal terms and/or comply with applicable legal requirements.
        </span>
      </p>

      <p class="privacy-text">
        <span>
          If you have questions or comments about your privacy rights, you may
          email us at
        </span>
        <span class="c8">hello@tigrisstudios.com</span>
        <span>.</span>
      </p>

      <p class="privacy-text">
        <span class="privacy-subtitle">
          11. CONTROLS FOR DO-NOT-TRACK FEATURES
        </span>
      </p>

      <p class="privacy-text">
        <span>
          Most web browsers and some mobile operating systems and mobile
          applications include a Do-Not-Track (&#39;DNT&#39;) feature or setting
          you can activate to signal your privacy preference not to have data
          about your online browsing activities monitored and collected. At this
          stage no uniform technology standard for recognising and implementing
          DNT signals has been finalised. As such, we do not currently respond
          to DNT browser signals or any other mechanism that automatically
          communicates your choice not to be tracked online. If a standard for
          online tracking is adopted that we must follow in the future, we will
          inform you about that practice in a revised version of this privacy
          notice.
        </span>
      </p>

      <p class="privacy-text">
        <span class="privacy-subtitle">
          12. DO UNITED STATES RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
        </span>
      </p>

      <p class="privacy-text">
        <span style={{ fontWeight: 700 }} class="c8 c11 ">
          In Short:
        </span>
        <span class="c5">
          If you are a resident of Virginia, Utah, Connecticut, Colorado or
          California, you are granted specific rights regarding access to your
          personal information.
        </span>
      </p>

      <p class="privacy-text">
        <span class="c39 c40 ">
          What categories of personal information do we collect?
        </span>
      </p>

      <p class="privacy-text">
        <span>
          We have collected the following categories of personal information in
          the past twelve (12) months:
        </span>
      </p>

      <table style={{ width: "100%", marginBottom: 30 }}>
        <thead>
          <tr>
            <th style={{ border: "1px solid black", padding: 8 }}>Category</th>
            <th
              className="privacy-text"
              style={{ border: "1px solid black", padding: 8 }}
            >
              Examples
            </th>
            <th
              className="privacy-text"
              style={{ border: "1px solid black", padding: 8 }}
            >
              Collected
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td
              className="privacy-text"
              style={{ border: "1px solid black", padding: 8 }}
            >
              A. Identifiers
            </td>
            <td
              className="privacy-text"
              style={{ border: "1px solid black", padding: 8 }}
            >
              Contact details, such as real name, alias, postal address,
              telephone or mobile contact number, unique personal identifier,
              online identifier, Internet Protocol address, email address, and
              account name
            </td>
            <td
              className="privacy-text"
              style={{
                border: "1px solid black",
                padding: 8,
                textAlign: "center",
              }}
            >
              YES
            </td>
          </tr>
          <tr>
            <td
              className="privacy-text"
              style={{ border: "1px solid black", padding: 8 }}
            >
              B. Personal information as defined in the California Customer
              Records statute
            </td>
            <td
              className="privacy-text"
              style={{ border: "1px solid black", padding: 8 }}
            >
              Name, contact information, education, employment, employment
              history, and financial information
            </td>
            <td
              className="privacy-text"
              style={{
                border: "1px solid black",
                padding: 8,
                textAlign: "center",
              }}
            >
              YES
            </td>
          </tr>
          <tr>
            <td
              className="privacy-text"
              style={{ border: "1px solid black", padding: 8 }}
            >
              C. Protected classification characteristics under state or federal
              law
            </td>
            <td
              className="privacy-text"
              style={{ border: "1px solid black", padding: 8 }}
            >
              Gender and date of birth
            </td>
            <td
              className="privacy-text"
              style={{
                border: "1px solid black",
                padding: 8,
                textAlign: "center",
              }}
            >
              YES
            </td>
          </tr>
          <tr>
            <td
              className="privacy-text"
              style={{ border: "1px solid black", padding: 8 }}
            >
              D. Commercial information
            </td>
            <td
              className="privacy-text"
              style={{ border: "1px solid black", padding: 8 }}
            >
              Transaction information, purchase history, financial details, and
              payment information
            </td>
            <td
              className="privacy-text"
              style={{
                border: "1px solid black",
                padding: 8,
                textAlign: "center",
              }}
            >
              NO
            </td>
          </tr>
          <tr>
            <td
              className="privacy-text"
              style={{ border: "1px solid black", padding: 8 }}
            >
              E. Biometric information
            </td>
            <td
              className="privacy-text"
              style={{ border: "1px solid black", padding: 8 }}
            >
              Fingerprints and voiceprints
            </td>
            <td
              className="privacy-text"
              style={{
                border: "1px solid black",
                padding: 8,
                textAlign: "center",
              }}
            >
              NO
            </td>
          </tr>
          <tr>
            <td
              className="privacy-text"
              style={{ border: "1px solid black", padding: 8 }}
            >
              F. Internet or other similar network activity
            </td>
            <td
              className="privacy-text"
              style={{ border: "1px solid black", padding: 8 }}
            >
              Browsing history, search history, online behaviour, interest data,
              and interactions with our and other websites, applications,
              systems, and advertisements
            </td>
            <td
              className="privacy-text"
              style={{
                border: "1px solid black",
                padding: 8,
                textAlign: "center",
              }}
            >
              NO
            </td>
          </tr>
          <tr>
            <td
              className="privacy-text"
              style={{ border: "1px solid black", padding: 8 }}
            >
              G. Geolocation data
            </td>
            <td
              className="privacy-text"
              style={{ border: "1px solid black", padding: 8 }}
            >
              Device location
            </td>
            <td
              className="privacy-text"
              style={{
                border: "1px solid black",
                padding: 8,
                textAlign: "center",
              }}
            >
              YES
            </td>
          </tr>
          <tr>
            <td
              className="privacy-text"
              style={{ border: "1px solid black", padding: 8 }}
            >
              H. Audio, electronic, visual, thermal, olfactory, or similar
              information
            </td>
            <td
              className="privacy-text"
              style={{ border: "1px solid black", padding: 8 }}
            >
              Images and audio, video or call recordings created in connection
              with our business activities
            </td>
            <td
              className="privacy-text"
              style={{
                border: "1px solid black",
                padding: 8,
                textAlign: "center",
              }}
            >
              NO
            </td>
          </tr>
          <tr>
            <td
              className="privacy-text"
              style={{ border: "1px solid black", padding: 8 }}
            >
              I. Professional or employment-related information
            </td>
            <td
              className="privacy-text"
              style={{ border: "1px solid black", padding: 8 }}
            >
              Business contact details in order to provide you our Services at a
              business level or job title, work history, and professional
              qualifications if you apply for a job with us
            </td>
            <td
              className="privacy-text"
              style={{
                border: "1px solid black",
                padding: 8,
                textAlign: "center",
              }}
            >
              NO
            </td>
          </tr>
          <tr>
            <td
              className="privacy-text"
              style={{ border: "1px solid black", padding: 8 }}
            >
              J. Education Information
            </td>
            <td
              className="privacy-text"
              style={{ border: "1px solid black", padding: 8 }}
            >
              Student records and directory information
            </td>
            <td
              className="privacy-text"
              style={{
                border: "1px solid black",
                padding: 8,
                textAlign: "center",
              }}
            >
              NO
            </td>
          </tr>
          <tr>
            <td
              className="privacy-text"
              style={{ border: "1px solid black", padding: 8 }}
            >
              K. Inferences drawn from collected personal information
            </td>
            <td
              className="privacy-text"
              style={{ border: "1px solid black", padding: 8 }}
            >
              Inferences drawn from any of the collected personal information
              listed above to create a profile or summary about, for example, an
              individual’s preferences and characteristics
            </td>
            <td
              className="privacy-text"
              style={{
                border: "1px solid black",
                padding: 8,
                textAlign: "center",
              }}
            >
              NO
            </td>
          </tr>
          <tr>
            <td
              className="privacy-text"
              style={{ border: "1px solid black", padding: 8 }}
            >
              L. Sensitive personal Information
            </td>
            <td
              className="privacy-text"
              style={{ border: "1px solid black", padding: 8 }}
            ></td>
            <td
              className="privacy-text"
              style={{
                border: "1px solid black",
                padding: 8,
                textAlign: "center",
              }}
            >
              NO
            </td>
          </tr>
        </tbody>
      </table>

      <p class="privacy-text">
        <span>
          We will use and retain the collected personal information as needed to
          provide the Services or for:
        </span>
      </p>
      <ul style={{ marginLeft: 50 }} class="lst-kix_3fugfwdkpki1-0 start">
        <li class="privacy-text li-bullet-0">
          <span>Category A - As long as the user has an account with us</span>
        </li>
      </ul>
      <ul style={{ marginLeft: 50 }} class="lst-kix_gkccnuo87vvj-0 start">
        <li class="privacy-text li-bullet-0">
          <span>Category B - As long as the user has an account with us</span>
        </li>
      </ul>
      <ul style={{ marginLeft: 50 }} class="lst-kix_pye4c64lffdf-0 start">
        <li class="privacy-text li-bullet-0">
          <span>Category C - As long as the user has an account with us</span>
        </li>
      </ul>
      <ul style={{ marginLeft: 50 }} class="lst-kix_a2zah234rdg8-0 start">
        <li class="privacy-text li-bullet-0">
          <span>Category G - As long as the user has an account with us</span>
        </li>
      </ul>
      <p class="privacy-text">
        <span>
          We may also collect other personal information outside of these
          categories through instances where you interact with us in person,
          online, or by phone or mail in the context of:
        </span>
      </p>
      <ul style={{ marginLeft: 50 }} class="lst-kix_3ftu1epswrgw-0 start">
        <li class="privacy-text li-bullet-0">
          <span>Receiving help through our customer support channels;</span>
        </li>
      </ul>
      <ul style={{ marginLeft: 50 }} class="lst-kix_tsgspv4b4rtk-0 start">
        <li class="privacy-text li-bullet-0">
          <span>Participation in customer surveys or contests; and</span>
        </li>
      </ul>
      <ul style={{ marginLeft: 50 }} class="lst-kix_3m83pjnlibd3-0 start">
        <li class="privacy-text li-bullet-0">
          <span>
            Facilitation in the delivery of our Services and to respond to your
            inquiries.
          </span>
        </li>
      </ul>
      <p class="privacy-text">
        <span class="c39 c40 ">
          How do we use and share your personal information?
        </span>
      </p>
      <p class="c1">
        <span></span>
      </p>

      <p class="privacy-text">
        <span class="c39 c40 ">
          Will your information be shared with anyone else?
        </span>
      </p>

      <p class="privacy-text">
        <span class="c8">
          We may disclose your personal information with our service providers
          pursuant to a written contract between us and each service provider.
        </span>
      </p>
      <p class="privacy-text">
        <span>
          We may use your personal information for our own business purposes,
          such as for undertaking internal research for technological
          development and demonstration. This is not considered to be
          &#39;selling&#39; of your personal information.
        </span>
      </p>

      <p class="privacy-text">
        <span>
          We have not disclosed, sold, or shared any personal information to
          third parties for a business or commercial purpose in the preceding
          twelve (12) months. We will not sell or share personal information in
          the future belonging to website visitors, users, and other consumers.
        </span>
      </p>

      <p class="privacy-text">
        <span class=" c32">California Residents</span>
      </p>

      <p class="privacy-text">
        <span>
          California Civil Code Section 1798.83, also known as the &#39;Shine
          The Light&#39; law permits our users who are California residents to
          request and obtain from us, once a year and free of charge,
          information about categories of personal information (if any) we
          disclosed to third parties for direct marketing purposes and the names
          and addresses of all third parties with which we shared personal
          information in the immediately preceding calendar year. If you are a
          California resident and would like to make such a request, please
          submit your request in writing to us using the contact information
          provided below.
        </span>
      </p>
      <p class="c1">
        <span></span>
      </p>
      <p class="privacy-text">
        <span>
          If you are under 18 years of age, reside in California, and have a
          registered account with the Services, you have the right to request
          removal of unwanted data that you publicly post on the Services. To
          request removal of such data, please contact us using the contact
          information provided below and include the email address associated
          with your account and a statement that you reside in California. We
          will make sure the data is not publicly displayed on the Services, but
          please be aware that the data may not be completely or comprehensively
          removed from all our systems (e.g. backups, etc.).
        </span>
      </p>
      <p class="c1">
        <span class="c3"></span>
      </p>
      <p class="privacy-subtitle">
        <span class="">CCPA Privacy Notice</span>
      </p>

      <p class="privacy-text">
        <span>
          This section applies only to California residents. Under the
          California Consumer Privacy Act (CCPA), you have the rights listed
          below.
        </span>
      </p>

      <p class="privacy-text">
        <span>
          The California Code of Regulations defines a &#39;residents&#39; as:
        </span>
      </p>

      <p style={{ fontWeight: 700 }} class="privacy-text">
        <span>
          (1) every individual who is in the State of California for other than
          a temporary or transitory purpose and
        </span>
      </p>
      <p style={{ fontWeight: 700 }} class="privacy-text">
        <span>
          (2) every individual who is domiciled in the State of California who
          is outside the State of California for a temporary or transitory
          purpose
        </span>
      </p>
      <p class="c1">
        <span></span>
      </p>
      <p class="privacy-text">
        <span>
          All other individuals are defined as &#39;non-residents&#39;.
        </span>
      </p>
      <p class="c1">
        <span></span>
      </p>
      <p class="privacy-text">
        <span>
          If this definition of &#39;resident&#39; applies to you, we must
          adhere to certain rights and obligations regarding your personal
          information.
        </span>
      </p>

      <p class="privacy-text">
        <span class="c39  c40">
          Your rights with respect to your personal data
        </span>
      </p>

      <p class="privacy-text">
        <span class="c19 c8">
          Right to request deletion of the data &mdash; Request to delete
        </span>
      </p>

      <p class="privacy-text">
        <span>
          You can ask for the deletion of your personal information. If you ask
          us to delete your personal information, we will respect your request
          and delete your personal information, subject to certain exceptions
          provided by law, such as (but not limited to) the exercise by another
          consumer of his or her right to free speech, our compliance
          requirements resulting from a legal obligation, or any processing that
          may be required to protect against illegal activities.
        </span>
      </p>

      <p class="privacy-text">
        <span class="c19 c8">Right to be informed &mdash; Request to know</span>
      </p>

      <p class="privacy-text">
        <span>Depending on the circumstances, you have a right to know:</span>
      </p>
      <ul style={{ marginLeft: 50 }} class="lst-kix_aeka26xapm7s-0 start">
        <li class="privacy-text li-bullet-0">
          <span>whether we collect and use your personal information;</span>
        </li>
      </ul>
      <ul style={{ marginLeft: 50 }} class="lst-kix_oy6bktrw7voi-0 start">
        <li class="privacy-text li-bullet-0">
          <span>the categories of personal information that we collect;</span>
        </li>
      </ul>
      <ul style={{ marginLeft: 50 }} class="lst-kix_obry69p92a2g-0 start">
        <li class="privacy-text li-bullet-0">
          <span>
            the purposes for which the collected personal information is used;
          </span>
        </li>
      </ul>
      <ul style={{ marginLeft: 50 }} class="lst-kix_8pq5gz75093m-0 start">
        <li class="privacy-text li-bullet-0">
          <span>
            whether we sell or share personal information to third parties;
          </span>
        </li>
      </ul>
      <ul style={{ marginLeft: 50 }} class="lst-kix_yamcbyve0f4f-0 start">
        <li class="privacy-text li-bullet-0">
          <span>
            the categories of personal information that we sold, shared, or
            disclosed for a business purpose;
          </span>
        </li>
      </ul>
      <ul style={{ marginLeft: 50 }} class="lst-kix_4cqc66o3perd-0 start">
        <li class="privacy-text li-bullet-0">
          <span>
            the categories of third parties to whom the personal information was
            sold, shared, or disclosed for a business purpose;
          </span>
        </li>
      </ul>
      <ul style={{ marginLeft: 50 }} class="lst-kix_zccnkoxioop7-0 start">
        <li class="privacy-text li-bullet-0">
          <span>
            the business or commercial purpose for collecting, selling, or
            sharing personal information; and
          </span>
        </li>
      </ul>
      <ul style={{ marginLeft: 50 }} class="lst-kix_4f8hdwk3uz10-0 start">
        <li class="privacy-text li-bullet-0">
          <span>
            the specific pieces of personal information we collected about you.
          </span>
        </li>
      </ul>
      <p class="privacy-text">
        <span>
          In accordance with applicable law, we are not obligated to provide or
          delete consumer information that is de-identified in response to a
          consumer request or to re-identify individual data to verify a
          consumer request.
        </span>
      </p>

      <p class="privacy-text">
        <span class="c19 c8">
          Right to Non-Discrimination for the Exercise of a Consumer&rsquo;s
          Privacy Rights
        </span>
      </p>

      <p class="privacy-text">
        <span>
          We will not discriminate against you if you exercise your privacy
          rights.
        </span>
      </p>

      <p class="privacy-text">
        <span class="c19 c8">
          Right to Limit Use and Disclosure of Sensitive Personal Information
        </span>
      </p>

      <p class="privacy-text">
        <span>
          We do not process consumer&#39;s sensitive personal information.
        </span>
      </p>

      <p class="privacy-text">
        <span class="c19 c8">Verification process</span>
      </p>

      <p class="privacy-text">
        <span>
          Upon receiving your request, we will need to verify your identity to
          determine you are the same person about whom we have the information
          in our system. These verification efforts require us to ask you to
          provide information so that we can match it with information you have
          previously provided us. For instance, depending on the type of request
          you submit, we may ask you to provide certain information so that we
          can match the information you provide with the information we already
          have on file, or we may contact you through a communication method
          (e.g. phone or email) that you have previously provided to us. We may
          also use other verification methods as the circumstances dictate.
        </span>
      </p>

      <p class="privacy-text">
        <span>
          We will only use personal information provided in your request to
          verify your identity or authority to make the request. To the extent
          possible, we will avoid requesting additional information from you for
          the purposes of verification. However, if we cannot verify your
          identity from the information already maintained by us, we may request
          that you provide additional information for the purposes of verifying
          your identity and for security or fraud-prevention purposes. We will
          delete such additionally provided information as soon as we finish
          verifying you.
        </span>
      </p>

      <p class="privacy-text">
        <span class="c19 c8">Other privacy rights</span>
      </p>
      <ul style={{ marginLeft: 50 }} class="lst-kix_96dvx5pinqof-0 start">
        <li class="privacy-text li-bullet-0">
          <span>
            You may object to the processing of your personal information.
          </span>
        </li>
      </ul>
      <ul style={{ marginLeft: 50 }} class="lst-kix_d2h4fzb0wnpp-0 start">
        <li class="privacy-text li-bullet-0">
          <span>
            You may request correction of your personal data if it is incorrect
            or no longer relevant, or ask to restrict the processing of the
            information.
          </span>
        </li>
      </ul>
      <ul style={{ marginLeft: 50 }} class="lst-kix_3l60ikj1rb78-0 start">
        <li class="privacy-text li-bullet-0">
          <span>
            You can designate an authorised agent to make a request under the
            CCPA on your behalf. We may deny a request from an authorised agent
            that does not submit proof that they have been validly authorised to
            act on your behalf in accordance with the CCPA.
          </span>
        </li>
      </ul>
      <ul style={{ marginLeft: 50 }} class="lst-kix_oxa52v5gk59t-0 start">
        <li class="privacy-text li-bullet-0">
          <span>
            You may request to opt out from future selling or sharing of your
            personal information to third parties. Upon receiving an opt-out
            request, we will act upon the request as soon as feasibly possible,
            but no later than fifteen (15) days from the date of the request
            submission.
          </span>
        </li>
      </ul>
      <p class="privacy-text">
        <span>
          To exercise these rights, you can contact us by email at
          hello@tigrisstudios.com, or by referring to the contact details at the
          bottom of this document. If you have a complaint about how we handle
          your data, we would like to hear from you.
        </span>
      </p>
      <p class="c1">
        <span class="c3"></span>
      </p>
      <p class="privacy-text">
        <span class="c23 ">Colorado Residents</span>
      </p>
      <p class="c1">
        <span class="c3"></span>
      </p>
      <p class="privacy-text">
        <span>
          This section applies only to Colorado residents. Under the Colorado
          Privacy Act (CPA), you have the rights listed below. However, these
          rights are not absolute, and in certain cases, we may decline your
          request as permitted by law.
        </span>
      </p>
      <ul style={{ marginLeft: 50 }} class="lst-kix_z47qf1utkyw8-0 start">
        <li class="privacy-text li-bullet-0">
          <span>
            Right to be informed whether or not we are processing your personal
            data
          </span>
        </li>
      </ul>
      <ul style={{ marginLeft: 50 }} class="lst-kix_d9buy0ksbwe0-0 start">
        <li class="privacy-text li-bullet-0">
          <span>Right to access your personal data</span>
        </li>
      </ul>
      <ul style={{ marginLeft: 50 }} class="lst-kix_mluav0isljdv-0 start">
        <li class="privacy-text li-bullet-0">
          <span>Right to correct inaccuracies in your personal data</span>
        </li>
      </ul>
      <ul style={{ marginLeft: 50 }} class="lst-kix_8sncbr1ijmx-0 start">
        <li class="privacy-text li-bullet-0">
          <span>Right to request deletion of your personal data</span>
        </li>
      </ul>
      <ul style={{ marginLeft: 50 }} class="lst-kix_nm6931qn4vlx-0 start">
        <li class="privacy-text li-bullet-0">
          <span>
            Right to obtain a copy of the personal data you previously shared
            with us
          </span>
        </li>
      </ul>
      <ul style={{ marginLeft: 50 }} class="lst-kix_188whivixyti-0 start">
        <li class="privacy-text li-bullet-0">
          <span>
            Right to opt out of the processing of your personal data if it is
            used for targeted advertising, the sale of personal data, or
            profiling in furtherance of decisions that produce legal or
            similarly significant effects (&#39;profiling&#39;)
          </span>
        </li>
      </ul>
      <p class="privacy-text">
        <span>To submit a request to exercise</span>
        <span class="c8">&nbsp;</span>
        <span>these</span>
        <span class="c8">&nbsp;</span>
        <span>rights described above, please email</span>
        <span class="c8">&nbsp;</span>
        <span>hello@tigrisstudios.com.</span>
      </p>

      <p class="privacy-text">
        <span>
          If we decline to take action regarding your request and you wish to
          appeal our decision, please email us at hello@tigrisstudios.com.
          Within forty-five (45) days of receipt of an appeal, we will inform
          you in writing of any action taken or not taken in response to the
          appeal, including a written explanation of the reasons for the
          decisions.
        </span>
      </p>

      <p class="privacy-text">
        <span class="c23 ">Connecticut Residents</span>
      </p>
      <p class="c1">
        <span class="c3"></span>
      </p>
      <p class="privacy-text">
        <span>
          This section applies only to Connecticut residents. Under the
          Connecticut Data Privacy Act (CTDPA), you have the rights listed
          below. However, these rights are not absolute, and in certain cases,
          we may decline your request as permitted by law.
        </span>
      </p>
      <ul style={{ marginLeft: 50 }} class="lst-kix_fpd3tatp5ckq-0 start">
        <li class="privacy-text li-bullet-0">
          <span>
            Right to be informed whether or not we are processing your personal
            data
          </span>
        </li>
      </ul>
      <ul style={{ marginLeft: 50 }} class="lst-kix_pmbg23k2vuxc-0 start">
        <li class="privacy-text li-bullet-0">
          <span>Right to access your personal data</span>
        </li>
      </ul>
      <ul style={{ marginLeft: 50 }} class="lst-kix_30b1gpj66nc-0 start">
        <li class="privacy-text li-bullet-0">
          <span>Right to correct inaccuracies in your personal data</span>
        </li>
      </ul>
      <ul style={{ marginLeft: 50 }} class="lst-kix_uk978ec2ubdy-0 start">
        <li class="privacy-text li-bullet-0">
          <span>Right to request deletion of your personal data</span>
        </li>
      </ul>
      <ul style={{ marginLeft: 50 }} class="lst-kix_v120cg5ei3gg-0 start">
        <li class="privacy-text li-bullet-0">
          <span>
            Right to obtain a copy of the personal data you previously shared
            with us
          </span>
        </li>
      </ul>
      <ul style={{ marginLeft: 50 }} class="lst-kix_fvegtb5gdqsc-0 start">
        <li class="privacy-text li-bullet-0">
          <span>
            Right to opt out of the processing of your personal data if it is
            used for targeted advertising, the sale of personal data, or
            profiling in furtherance of decisions that produce legal or
            similarly significant effects (&#39;profiling&#39;)
          </span>
        </li>
      </ul>
      <p class="privacy-text">
        <span>
          To submit a request to exercise these rights described above, please
          email hello@tigrisstudios.com.
        </span>
      </p>

      <p class="privacy-text">
        <span>
          If we decline to take action regarding your request and you wish to
          appeal our decision, please email us at hello@tigrisstudios.com.
          Within sixty (60) days of receipt of an appeal, we will inform you in
          writing of any action taken or not taken in response to the appeal,
          including a written explanation of the reasons for the decisions.
        </span>
      </p>

      <p class="privacy-text c29">
        <span class=" c23">Utah Residents</span>
      </p>

      <p class="privacy-text c29">
        <span>
          This section applies only to Utah residents. Under the Utah Consumer
          Privacy Act (UCPA), you have the rights listed below. However, these
          rights are not absolute, and in certain cases, we may decline your
          request as permitted by law.
        </span>
      </p>
      <ul style={{ marginLeft: 50 }} class="lst-kix_q2ganu4dk7cc-0 start">
        <li class="privacy-text li-bullet-0">
          <span>
            Right to be informed whether or not we are processing your personal
            data
          </span>
        </li>
      </ul>
      <ul style={{ marginLeft: 50 }} class="lst-kix_ayq3gfinjam1-0 start">
        <li class="privacy-text li-bullet-0">
          <span>Right to access your personal data</span>
        </li>
      </ul>
      <ul style={{ marginLeft: 50 }} class="lst-kix_djrqnw88850z-0 start">
        <li class="privacy-text li-bullet-0">
          <span>Right to request deletion of your personal data</span>
        </li>
      </ul>
      <ul style={{ marginLeft: 50 }} class="lst-kix_udyidx4ml0tt-0 start">
        <li class="privacy-text li-bullet-0">
          <span>
            Right to obtain a copy of the personal data you previously shared
            with us
          </span>
        </li>
      </ul>
      <ul style={{ marginLeft: 50 }} class="lst-kix_ffvqm9hv6ef2-0 start">
        <li class="privacy-text li-bullet-0">
          <span>
            Right to opt out of the processing of your personal data if it is
            used for targeted advertising or the sale of personal data
          </span>
        </li>
      </ul>
      <p class="privacy-text c29">
        <span>
          To submit a request to exercise these rights described above, please
          email
        </span>
        <span class="c8">hello@tigrisstudios.com</span>
        <span>.</span>
      </p>

      <p class="privacy-subtitle">
        <span class="c23 ">Virginia Residents</span>
      </p>
      <p class="c1">
        <span class="c3"></span>
      </p>
      <p class="privacy-text">
        <span>Under the Virginia Consumer Data Protection Act (VCDPA):</span>
      </p>
      <p class="c1">
        <span class="c3"></span>
      </p>
      <p class="privacy-text">
        <span>
          &#39;Consumer&#39; means a natural person who is a resident of the
          Commonwealth acting only in an individual or household context. It
          does not include a natural person acting in a commercial or employment
          context.
        </span>
      </p>
      <p class="c1">
        <span class="c3"></span>
      </p>
      <p class="privacy-text">
        <span>
          &#39;Personal data&#39; means any information that is linked or
          reasonably linkable to an identified or identifiable natural person.
          &#39;Personal data&#39; does not include de-identified data or
          publicly available information.
        </span>
      </p>
      <p class="c1">
        <span class="c3"></span>
      </p>
      <p class="privacy-text">
        <span>
          &#39;Sale of personal data&#39; means the exchange of personal data
          for monetary consideration.
        </span>
      </p>
      <p class="c1">
        <span class="c3"></span>
      </p>
      <p class="privacy-text">
        <span>
          If this definition of &#39;consumer&#39; applies to you, we must
          adhere to certain rights and obligations regarding your personal data.
        </span>
      </p>

      <p class="privacy-text">
        <span class="c19 c8">
          Your rights with respect to your personal data
        </span>
      </p>
      <ul style={{ marginLeft: 50 }} class="lst-kix_bonqungl9xxv-0 start">
        <li class="privacy-text li-bullet-0">
          <span>
            Right to be informed whether or not we are processing your personal
            data
          </span>
        </li>
      </ul>
      <ul style={{ marginLeft: 50 }} class="lst-kix_dyzbrn9nkq1z-0 start">
        <li class="privacy-text li-bullet-0">
          <span>Right to access your personal data</span>
        </li>
      </ul>
      <ul style={{ marginLeft: 50 }} class="lst-kix_k1z1r9rbgbl-0 start">
        <li class="privacy-text li-bullet-0">
          <span>Right to correct inaccuracies in your personal data</span>
        </li>
      </ul>
      <ul style={{ marginLeft: 50 }} class="lst-kix_8yupq9d7dkmj-0 start">
        <li class="privacy-text li-bullet-0">
          <span>Right to request deletion of your personal data</span>
        </li>
      </ul>
      <ul style={{ marginLeft: 50 }} class="lst-kix_i0cccqfaddz5-0 start">
        <li class="privacy-text li-bullet-0">
          <span>
            Right to obtain a copy of the personal data you previously shared
            with us
          </span>
        </li>
      </ul>
      <ul style={{ marginLeft: 50 }} class="lst-kix_2gbtnugxylkk-0 start">
        <li class="privacy-text li-bullet-0">
          <span>
            Right to opt out of the processing of your personal data if it is
            used for targeted advertising, the sale of personal data, or
            profiling in furtherance of decisions that produce legal or
            similarly significant effects (&#39;profiling&#39;)
          </span>
        </li>
      </ul>
      <p class="privacy-text">
        <span class="c8 c19">
          Exercise your rights provided under the Virginia VCDPA
        </span>
      </p>
      <p class="c1">
        <span class="c3"></span>
      </p>
      <p class="privacy-text">
        <span>You may contact us by email at hello@tigrisstudios.com.</span>
      </p>
      <p class="c1">
        <span class="c3"></span>
      </p>
      <p class="privacy-text">
        <span>
          If you are using an authorised agent to exercise your rights, we may
          deny a request if the authorised agent does not submit proof that they
          have been validly authorised to act on your behalf.
        </span>
      </p>

      <p class="privacy-text">
        <span class="c19 c8">Verification process</span>
      </p>
      <p class="c1">
        <span class="c3"></span>
      </p>
      <p class="privacy-text">
        <span>
          We may request that you provide additional information reasonably
          necessary to verify you and your consumer&#39;s request. If you submit
          the request through an authorised agent, we may need to collect
          additional information to verify your identity before processing your
          request.
        </span>
      </p>
      <p class="c1">
        <span class="c3"></span>
      </p>
      <p class="privacy-text">
        <span>
          Upon receiving your request, we will respond without undue delay, but
          in all cases, within forty-five (45) days of receipt. The response
          period may be extended once by forty-five (45) additional days when
          reasonably necessary. We will inform you of any such extension within
          the initial 45-day response period, together with the reason for the
          extension.
        </span>
      </p>

      <p class="privacy-text">
        <span class="c19 c8">Right to appeal</span>
      </p>
      <p class="c1">
        <span class="c3"></span>
      </p>
      <p class="privacy-text">
        <span class="c8">
          If we decline to take action regarding your request, we will inform
          you of our decision and reasoning behind it. If you wish to appeal our
          decision, please email us at hello@tigrisstudios.com. Within sixty
          (60) days of receipt of an appeal, we will inform you in writing of
          any action taken or not taken in response to the appeal, including a
          written explanation of the reasons for the decisions.
        </span>
      </p>
      <br />
      <p class=" c21">13. DO OTHER REGIONS HAVE SPECIFIC PRIVACY RIGHTS?</p>
      <p class="c1">
        <span class="c3"></span>
      </p>
      <p class="privacy-text">
        <span style={{ fontWeight: 700 }} class="c8 c11 ">
          In Short:
        </span>
        <span class="c5">
          &nbsp;You may have additional rights based on the country you reside
          in.
        </span>
      </p>
      <p class="c1">
        <span class="c3"></span>
      </p>
      <p style={{ fontWeight: 700 }} class="privacy-text">
        Australia and New Zealand
      </p>
      <p class="c1">
        <span class="c3"></span>
      </p>
      <p class="privacy-text">
        <span>
          We collect and process your personal information under the obligations
          and conditions set by Australia&#39;s Privacy Act 1988 and New
          Zealand&#39;s Privacy Act 2020 (Privacy Act).
        </span>
      </p>
      <p class="c1">
        <span class="c3"></span>
      </p>
      <p class="privacy-text">
        <span>
          This privacy notice satisfies the notice requirements defined in both
          Privacy Acts, in particular: what personal information we collect from
          you, from which sources, for which purposes, and other recipients of
          your personal information.
        </span>
      </p>
      <p class="c1">
        <span class="c3"></span>
      </p>
      <p class="privacy-text">
        <span>
          If you do not wish to provide the personal information necessary to
          fulfill their applicable purpose, it may affect our ability to provide
          our services, in particular:
        </span>
      </p>
      <ul style={{ marginLeft: 50 }} class="lst-kix_bb7y9a5exr5q-0 start">
        <li class="privacy-text li-bullet-0">
          <span>offer you the products or services that you want</span>
        </li>
      </ul>
      <ul style={{ marginLeft: 50 }} class="lst-kix_l4fmd6n5502z-0 start">
        <li class="privacy-text li-bullet-0">
          <span>respond to or help with your requests</span>
        </li>
      </ul>
      <ul style={{ marginLeft: 50 }} class="lst-kix_tn52wvrmc1dy-0 start">
        <li class="privacy-text li-bullet-0">
          <span>manage your account with us</span>
        </li>
      </ul>
      <ul style={{ marginLeft: 50 }} class="lst-kix_a36edqysbgzr-0 start">
        <li class="privacy-text li-bullet-0">
          <span>confirm your identity and protect your account</span>
        </li>
      </ul>
      <p class="privacy-text">
        <span class="c8">
          At any time, you have the right to request access to or correction of
          your personal information.
        </span>
      </p>
      <p class="privacy-text">
        <span class="c33">I</span>
        <span class="c8">
          f you believe we are unlawfully processing your personal information,
          you have the right to submit a complaint about a breach of the
          Australian Privacy Principles to the
        </span>
        <span class="c24">
          <a
            class="c15"
            href="https://www.google.com/url?q=https://www.oaic.gov.au/privacy/privacy-complaints/lodge-a-privacy-complaint-with-us&amp;sa=D&amp;source=editors&amp;ust=1710525136615851&amp;usg=AOvVaw0en8iWYQ6AynGVlb-LZceK"
          >
            Office of the Australian Information Commissioner
          </a>
        </span>
        <span class="c8">
          &nbsp;and a breach of New Zealand&#39;s Privacy Principles to the
        </span>
        <span class="c24">
          <a
            class="c15"
            href="https://www.google.com/url?q=https://www.privacy.org.nz/your-rights/making-a-complaint/&amp;sa=D&amp;source=editors&amp;ust=1710525136616410&amp;usg=AOvVaw2coNZoUOKD6CuyN2hOlJBR"
          >
            Office of New Zealand Privacy Commissioner
          </a>
        </span>
        <span>.</span>
      </p>
      <p class="c1">
        <span class="c3"></span>
      </p>
      <p class="privacy-text">
        <span class="c23 ">Republic of South Africa</span>
      </p>
      <p class="c1">
        <span class="c3"></span>
      </p>
      <p class="privacy-text">
        <span class="c8">
          At any time, you have the right to request access to or correction of
          your personal information.
        </span>
      </p>
      <p class="privacy-text">
        <span>
          If you are unsatisfied with the manner in which we address any
          complaint with regard to our processing of personal information, you
          can contact the office of the regulator, the details of which are:
        </span>
      </p>
      <p class="c1">
        <span class="c3"></span>
      </p>
      <p class="privacy-text">
        <span class="c24">
          <a
            class="c15"
            href="https://www.google.com/url?q=https://inforegulator.org.za/&amp;sa=D&amp;source=editors&amp;ust=1710525136617581&amp;usg=AOvVaw0URPLNPiDMp4PGzBiQzVWJ"
          >
            The Information Regulator (South Africa)
          </a>
        </span>
      </p>
      <p class="privacy-text">
        <span>General enquiries: </span>
        <span class="privacy-text c35">enquiries@inforegulator.org.za</span>
      </p>
      <p class="privacy-text">
        <span>Complaints (complete POPIA/PAIA form 5): </span>
        <span class="c35">PAIAComplaints@inforegulator.org.za</span>
        <span>&nbsp;&amp; </span>
        <span class="privacy-text c35">
          POPIAComplaints@inforegulator.org.za
        </span>
      </p>

      <p class="privacy-text">
        <span class=" c21">14. DO WE MAKE UPDATES TO THIS NOTICE?</span>
      </p>

      <p class="privacy-text">
        <span style={{ fontWeight: 700 }} class="c8 c11 ">
          In Short:
        </span>
        <span class="c5">
          Yes, we will update this notice as necessary to stay compliant with
          relevant laws.
        </span>
      </p>

      <p class="privacy-text">
        <span>
          We may update this privacy notice from time to time. The updated
          version will be indicated by an updated &#39;Revised&#39; date and the
          updated version will be effective as soon as it is accessible. If we
          make material changes to this privacy notice, we may notify you either
          by prominently posting a notice of such changes or by directly sending
          you a notification. We encourage you to review this privacy notice
          frequently to be informed of how we are protecting your information.
        </span>
      </p>

      <p class="privacy-text">
        <span class="privacy-subtitle">
          15. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
        </span>
      </p>

      <p class="privacy-text">
        <span>
          If you have questions or comments about this notice, you may email us
          at hello@tigrisstudios.com or contact us by post at:
        </span>
      </p>

      <p class="privacy-text">
        <span>Tigris Studios FZCO</span>
      </p>
      <p class="privacy-text">
        <span>16235-001, IFZA Business Park, DDP</span>
      </p>
      <p class="privacy-text">
        <span class="c8">Dubai</span>
        <span>, </span>
        <span>UAE</span>
      </p>
      <p class="privacy-text">
        <span>United Arab Emirates</span>
      </p>

      <p class="privacy-text">
        <span class="privacy-subtitle">
          16. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM
          YOU?
        </span>
      </p>

      <p class="privacy-text">
        <span class="c8">
          You have the right to request access to the personal information we
          collect from you, change that information, or delete it.
        </span>
      </p>
    </div>
  );
};

export default PrivacyPolicyEng;
