import React, { useEffect, useState } from "react";
import "./styles.css";

import { IMAGES } from "../../../assets";

const Top = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const is600pxOrMore = windowWidth > 600;

  return (
    <section
      className="top-wrapper"
      // style={{ backgroundImage: `url(${IMAGES.topBg})` }}
    >
      <div className="container top-container">
        <div className="top-item top-info">
          <h1 className="top-info-title">Niya</h1>
          <p className="top-info-description">
            Track your salah and never leave anything qada!
          </p>
          <p className="top-info-description">
            Do you ever miss your salah for some reason? Do you want to make up
            your qada but forgot which salah you missed? Niya is here to help!
          </p>
          <p className="top-info-description top-info-help">
            Niya is here to help
          </p>
          {!is600pxOrMore && (
            <div className="top-item top-info-preview">
              <div className="top-info-preview-wrapper">
                <img src={IMAGES.preview} />
              </div>
            </div>
          )}
          <div className="top-info-buttons">
            <div className="top-info-buttons-item">
              <a
                href="https://apps.apple.com/ua/app/niya-muslim-prayer-tracker/id1668179563"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={IMAGES.appStore} />
              </a>
            </div>
            <div className="top-info-buttons-item">
              <a
                href="https://play.google.com/store/apps/details?id=com.niyaa&pcampaignid=web_share"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={IMAGES.googlePlay} />
              </a>
            </div>
          </div>
        </div>
        {is600pxOrMore && (
          <div className="top-item top-info-preview">
            <div className="top-info-preview-wrapper">
              <img src={IMAGES.preview} />
            </div>
          </div>
        )}
      </div>
    </section>
  );
};

export default Top;
